.header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.back_btn > img {
  position: absolute;
  left: 20px;
  bottom: 0px;

  width: 20px;
  height: 20px;
}

.header > h1 {
  font-size: 16px;
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
}

@charset "utf-8";

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #30303055;
}

.unityBack {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
}

.h-100 {
  height: 100%;
}

.h-100n {
  height: calc(100vh - 80px);
  /* padding-top: 80px; */
}

.w-100p {
  width: 100%;
}

.w-50p {
  width: 50%;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex;
}

.grow-1 {
  flex-grow: 1;
}

.grow-3 {
  flex-grow: 3;
}

.flex-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center !important;
}

.flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-top {
  vertical-align: top;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px;
}

.fs-23 {
  font-size: 23px;
}

.fs-25 {
  font-size: 25px;
}

.normal {
  font-family: 'NotoSansKR-Regular', 'Roboto-Regular' !important;
  font-weight: 300;
}

.medium {
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium' !important;
  font-weight: 500;
}

.bold {
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold' !important;
}

.color-red {
  color: #e76b84;
}

.color-blue {
  color: #0064ff;
}

.color-black {
  color: #202632 !important;
}

.color-gray {
  color: #8f9298;
}

/*border*/
.border {
  border: 1px solid #c7c8cb;
}

.bd-bottom {
  border-bottom: 1px solid #e8e9ea;
}

.bd-top {
  border-top: 1px solid #e8e9ea;
}

.bd-0 {
  border: 0 !important;
}

.ellipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*margin*/
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-n2 {
  margin-top: -2px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-25 {
  margin-right: 25px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-25 {
  margin-left: 25px;
}

/*padding*/
.pd-0 {
  padding: 0 !important;
}

.pd-side {
  padding: 0 22px;
}

.pd-tb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pd-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pd-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pd-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-100 {
  padding-top: 100px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-22 {
  padding: 22px;
}

.lh-14 {
  line-height: 14px;
}

/*background*/
.bg-gray {
  background: #e8e9ea;
}

.bg-rgray {
  background: #f3f4f4;
}

.text-line {
  text-decoration: line-through;
}

/*header*/
header {
  background: #fff;
}

header {
  padding-top: 48px;
  padding-left: 22px;
  padding-right: 22px;
}

header .btn-prev {
  display: block;
  width: 20px;
  height: 20px;
  background: url('../images/arrow_left.png') no-repeat 0 0 / 100% auto;
}

header .btn-share {
  display: block;
  width: 20px;
  height: 20px;
  background: url('../images/ico_share.png') no-repeat 0 0 / 100% auto;
}

header .btn-close {
  display: block;
  width: 20px;
  height: 20px;
  background: url('../images/ico_close.png') no-repeat 0 0 / 100% auto;
}

header .btn-prev span,
header .btn-share span,
header .btn-close span {
  font-size: 0;
  height: 0;
}

.btn-keep {
  display: block;
  width: 20px !important;
  height: 20px;
  background: url('../images/ico_keep.png') no-repeat 0 0 / 100% auto;
}

.btn-keepon {
  display: block;
  width: 20px;
  height: 20px;
  background: url('../images/ico_keepon.png') no-repeat 0 0 / 100% auto;
}

.btn-cart {
  display: block;
  width: 19px !important;
  height: 19px;
  background: url('../images/ico_cart_b.png') no-repeat 0 0 / 100% auto;
}

.btn-my {
  display: block;
  width: 19px;
  height: 19px;
  background: url('../images/ico_user.png') no-repeat 0 0 / 100% auto;
}

.btn-delete {
  display: block;
  width: 14px;
  height: 14px;
  background: url('../images/ico_delete.png') no-repeat 0 0 / 100% auto;
}

.btn-setting {
  display: block;
  width: 19px !important;
  height: 19px;
  background: url('../images/ico_setting.png') no-repeat 0 0 / 100% auto;
}

.btn-keep span,
.btn-ico span,
.btn-cart span,
.btn-delete span,
.btn-setting span {
  font-size: 0;
  height: 0;
}

/*sub header*/
header.sub {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

header.sub h1 {
  font-size: 16px;
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
}

header.sub a {
  position: absolute;
  left: 22px;
}

header.sub a.btn-share,
header.sub a.right {
  position: absolute;
  left: auto;
  right: 22px;
  top: 48px;
  z-index: 100;
  width: 20px;
}

header.sub .right {
  position: absolute;
  left: auto;
  right: 22px;
  top: 52px;
  width: auto;
}

header.sub .right a {
  position: relative;
  left: auto;
  display: inline-block;
}

header.sub .right a:first-child {
  margin-right: 15px;
}

header.sub .left {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 9999;
}

header.sub.funding {
  padding: 0;
  background: #8f9298;
}

header.sub.funding a {
  /*
    position: absolute;
    top: 22px;
    left: 22px;
    z-index: 9999;
*/
}

header.sub.funding .headerSwiper .swiper-pagination,
header.sub.shop .headerSwiper .swiper-pagination {
  background: #c7c8cb;
  width: 40px;
  padding: 5px 9px;
  left: auto;
  right: 20px;
  bottom: 20px;
}

header.sub.shop {
  padding: 0;
  background: #8f9298;
}

header.sub.shopsub {
  padding: 0;
  background: #fff;
  height: 85px;
}

header.sub.shop .top,
header.sub.shopsub .top {
  position: absolute;
  width: calc(100% - 44px);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
  padding-left: 22px;
  padding-right: 22px;
}

header.sub.shop .top a,
header.sub.shopsub .top a {
  position: relative;
  left: auto;
}

header.sub.shop .top.scroll-top,
header.sub.shopsub .top.scroll-top {
  position: fixed;
  background: #fff;
  padding-bottom: 22px;
  border-bottom: 1px solid #c7c8cb;
}

section.one-page {
  height: calc(100% - 105px);
  padding-left: 22px;
  padding-right: 22px;
}

section.one-page > div {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*layout*/
.full-sub {
  height: calc(100% - 103px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.full-tab {
  height: calc(100% - 143px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*login*/
.login {
  padding-left: 22px;
  padding-right: 22px;
}

.login h1 {
  padding-top: 3%;
  padding-bottom: 5%;
  font-size: 30px;
  font-family: 'NotoSansKR-Bold';
}

.login-100 {
  height: calc(100vh - 100px);
}

.btn-sns-tit {
  margin-top: 24px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 15px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

.btn-sns {
  width: 100%;
  height: 13%;
  margin-top: 24px;

  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../images/kakao_login_large_wide.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #fee500;
}

.btn-join {
  margin-bottom: 10%;
  position: absolute;
  bottom: 10px;
}
.btn-join:hover {
  background: black !important;
  color: white;
}

.find-idpw {
  border-top: 1px solid #202632;
  border-bottom: 1px solid #202632;
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

/*join*/
.join h1 {
  padding-top: 37px;
  padding-bottom: 37px;
  font-size: 20px;
  font-family: 'NotoSansKR-Bold';
  word-break: keep-all;
}

section.join {
  height: calc(100vh - 100px);
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

section.join label {
  font-size: 14px;
}

.join .form-checkbox {
  margin-bottom: 20px;
}

.join .form-checkbox.border-bottom {
  border-bottom: 2px solid #c7c8cb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.join .form-checkbox.d-flex {
  display: flex;
}

.join .form-checkbox.d-flex a {
  margin-left: 15px;
  font-family: 'NotoSansKR-Bold';
}

.terms {
  height: 70%;
  border: 1px solid #8f9298;
  padding: 15px;
}

.input-info {
  margin-bottom: 20px;
}

.input-info p {
  margin-right: 14px;
  color: #c6c7ca;
}

.input-info p.color-black {
  margin-right: 14px;
  color: #202632;
}

.input-info p img {
  width: 20px;
}

.complet {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.complet img {
  width: 107px;
}

.complet p {
  margin-top: 34px;
  font-size: 25px;
  font-family: 'NotoSansKR-Bold';
}

/*button*/
.btn-area {
  margin-top: 30px;
}

.btn {
  display: block;
  width: 100%;
  padding: 14px 0;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
}

.btn-small {
  display: inline-block;
  border-radius: 0;
  width: auto;
  padding: 0 11px;
}

.btn.btn-pink {
  background: #e76b84;
  color: #fff;
  text-align: center;
}

.btn.btn-gray {
  background: #cfd0d4;
  color: #fff;
}

.btn.btn-rgray {
  background: #e8e9ea;
  color: #202632;
}

.btn.btn-outline {
  background: #fff;
  border: 1px solid #202632;
  font-size: 14px;
  font-family: 'NotoSansKR-Medium';
}

.btn-detail {
  width: 14px;
  display: inline-block;
}

.btn-detail img {
  width: 100%;
  vertical-align: middle;
}

/*form*/
.form-input {
  margin-bottom: 8px;
}

label {
  font-size: 14px;
}

input[type='checkbox'] {
  display: none;
}

input[class='check1'] + label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #bcbcbc;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

input[class='check1']:checked + label {
  background: url('../images/checkbox_checked.png') no-repeat 0 0 /100% auto;
  width: 20px;
  height: 20px;
  border: 0;
}

input[class='check1']:checked + label::after {
  content: '';
  width: 19px;
  height: 19px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}

input[class='check2'] + label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../images/check_off.png') no-repeat 0 0 /100% auto;
  border: 0;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

input[class='check2']:checked + label {
  background: url('../images/check_on.png') no-repeat 0 0 /100% auto;
  width: 20px;
  height: 20px;
  border: 0;
}

input[class='check2']:checked + label::after {
  content: '';
  width: 19px;
  height: 19px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}

p.label {
  width: 78%;
  display: inline-block;
  margin-left: 8px;
}

/*checkbox 장바구니*/
.cart-checkbox {
  position: relative;
}

.cart-checkbox input[class='check'] + label {
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 1px solid #c6c7ca;
  border-radius: 19px;
  box-sizing: border-box;
  position: relative;
  margin-top: -2px;
}

.cart-checkbox input[class='check']:checked + label {
  background: #000;
  width: 19px;
  height: 19px;
  background: #000 url('../images/cart_check_on.png') no-repeat 0 0 / 100% auto;
  border: 0;
}

.cart-checkbox input[class='check']:checked + label::after {
  content: '';
  width: 19px;
  height: 19px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}

.cart-checkbox p.label {
  width: auto;
  display: inline-block;
  margin-left: 8px;
}

/*main*/
header.main {
  padding: 20px;
  background: #202632;
}

.btn-notice {
  width: 19px;
  height: 19px;
  background: url('../images/ico_notice.png') no-repeat 0 0 / 100% auto;
  display: inline-block;
}

.main-wrap nav {
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: 2px solid #e8e9ea;
  background: #fff;
  display: flex;
  justify-content: space-around;
  z-index: 9999;
}

.main-wrap nav ul {
  display: flex;
}

.main-wrap nav li {
  width: 25%;
}

.main-wrap nav a {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 5px;
  color: #c6c7ca;
}

.main-wrap nav a.active {
  color: #202632;
}

.main-wrap nav a.menu1 {
  width: 55px;
  background: url('../images/menu1_off.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.menu2 {
  width: 55px;
  background: url('../images/menu2_off.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.menu3 {
  width: 55px;
  background: url('../images/menu3_off.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.menu4 {
  width: 55px;
  background: url('../images/menu4_off.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.menu5 {
  width: 55px;
  background: url('../images/monitor_off.svg') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.active.menu1 {
  width: 55px;
  background: url('../images/menu1.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.active.menu2 {
  width: 55px;
  background: url('../images/menu2.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.active.menu3 {
  width: 55px;
  background: url('../images/menu3.png') no-repeat center 8px / 21px auto;
}

.main-wrap nav a.active.menu4 {
  width: 55px;
  background: url('../images/menu4.png') no-repeat center 8px / 21px auto;
}
.main-wrap nav a.active.menu5 {
  width: 55px;
  background: url('../images/monitor_on.svg') no-repeat center 8px / 21px auto;
}

/*main header swiper*/
.headerSwiper {
  /*padding: 20px;*/
}

.headerSwiper .swiper-slide {
  width: 80%;
  height: auto;
  background: #202632;
  color: #fff;
}

.headerSwiper .swiper-slide div {
  width: 100%;
  text-align: left;
}

.headerSwiper .swiper-pagination {
  background: #c7c8cb;
  width: 40px;
  padding: 5px 9px;
  left: auto;
  right: 0;
  bottom: 0;
}

.headerSwiper .swiper-pagination.rb-15 {
  right: 15px;
  bottom: 15px;
}

.btn-notice span {
  width: 0;
  height: 0;
  font-size: 0;
}

/*swiper*/
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*main slide*/
.mySwiper {
}

.mySwiper .swiper-slide {
  width: 130px;
}

/*review*/
.reviewSwiper .swiper-slide {
  width: 79px;
}

.btn-more {
  color: #c7c8cb;
  padding-right: 20px;
  position: relative;
  margin-top: 5px;
  position: absolute;
  right: 22px;
  top: 40px;
}

.btn-more:after {
  display: inline-block;
  content: '';
  width: 14px;
  height: 14px;
  background: url('../images/more.png') no-repeat right 0 / 100% auto;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
}

.btn-more-gray {
  background: #e8e9ea;
  padding: 3px 11px;
  font-size: 12px;
  font-family: 'NotoSansKR-Medium';
}

.slide-wrap {
  padding-left: 22px;
}

.title-wrap {
  padding: 40px 22px 10px;
  position: relative;
}

.title-wrap h2 {
  font-size: 20px;
}

.title-wrap span {
  display: block;
  font-family: 'NotoSansKR-Regular';
  font-weight: normal;
  font-size: 14px;
  color: #8f9298;
  margin-top: 5px;
}

.mySwiper p {
  font-size: 13px;
  color: #8f9298;
  word-break: keep-all;
}

.mySwiper .slide-title {
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
  font-size: 18px;
  color: #202632;
  margin-top: 10px;
}

.progress {
  background: #202632;
  width: 100%;
  height: 2px;
  margin-bottom: 5px;
}

.progress-bar {
  background: #eb93a5;
  height: 2px;
}

.main-box {
  background: #fdf4f6;
  padding: 18px 0;
  margin: 0 22px;
  border-radius: 20px;
  display: flex;
}

.main-box dl {
  width: 50%;
}

.main-box dl:first-child {
  border-right: 1px solid #202632;
}

.main-box dt,
.main-box dd {
  text-align: center;
}

.main-box dd {
  margin-top: 10px;
}

.main-box .border {
  border: 1px solid #000000;
  border-radius: 20px;
  display: inline-block;
  padding: 0 15px;
}

.ico-star {
  display: inline-block;
  width: 14px;
  margin-right: 3px;
  vertical-align: middle;
  margin-top: -3px;
}

.ico-star img {
  width: 100%;
}

.ico-share,
.ico-cart {
  width: 19px;
}

.ico-share img,
.ico-cart img {
  width: 100%;
}

/* sub */
/* notice */
.notice-list {
  padding: 15px 22px;
}

.notice-list.bg-gray {
  background: #ececee;
}

.notice-list div {
  font-size: 13px;
  position: relative;
}

.notice-list div span {
  position: absolute;
  right: 22px;
  color: #6f737a;
}

.notice-list p {
  font-size: 15px;
  font-family: 'NotoSansKR-Medium';
  margin-top: 10px;
}

/*tab*/
.tabs.ui-widget.ui-widget-content {
  border: 0;
  border-radius: 0;
}

.tabs .ui-widget-header {
  border: 0;
  background: #fff;
  color: #333333;
  font-weight: bold;
}

.tabs.ui-tabs {
  padding: 0;
}

.tabs.ui-tabs .ui-tabs-nav {
  padding: 0;
}

.tabs.ui-tabs .ui-tabs-nav li {
  width: 50%;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.tabs.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-bottom: 0;
  padding-bottom: 0;
}

.tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  float: none;
  text-align: center;
}

/*conditions-tab*/
.tabs.ui-tabs.condition-tabs .ui-tabs-nav li {
  display: inline-block;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.tabs.condition-tabs .ui-state-default,
.tabs.condition-tabs .ui-widget-content .ui-state-default,
.tabs.condition-tabs .ui-widget-header .ui-state-default,
.tabs.condition-tabs .ui-button,
html .tabs.condition-tabs .ui-button.ui-state-disabled:hover,
html .tabs.condition-tabs .ui-button.ui-state-disabled:active {
  background: #fff;
}

.tabs.condition-tabs .ui-state-default a,
.tabs.condition-tabs .ui-state-default a:link,
.tabs.condition-tabs .ui-state-default a:visited,
.tabs.condition-tabs a.ui-button,
.tabs.condition-tabs a:link.ui-button,
.tabs.condition-tabs a:visited.ui-button,
.tabs.condition-tabs .ui-button {
  font-size: 17px;
  font-family: 'NotoSansKR-Medium';
  color: #c7c9cc;
  margin-left: 22px;
  padding: 0.5em 1em;
}

.tabs.condition-tabs li:nth-child(2).ui-state-default a,
.tabs.condition-tabs li:nth-child(2).ui-state-default a:link,
.tabs.condition-tabs li:nth-child(2).ui-state-default a:visited,
.tabs.condition-tabs li:nth-child(2) a.ui-button,
.tabs.condition-tabs li:nth-child(2) a:link.ui-button,
.tabs.condition-tabs li:nth-child(2) a:visited.ui-button,
.tabs.condition-tabs .ui-button {
  margin-right: 22px;
  margin-left: 0;
}

.tabs.condition-tabs.ui-state-active,
.tabs.condition-tabs.ui-widget-content .ui-state-active,
.tabs.condition-tabs .ui-widget-header .ui-state-active,
.tabs.condition-tabs a.ui-button:active,
.tabs.condition-tabs .ui-button:active,
.tabs.condition-tabs .ui-button.ui-state-active:hover {
  background: #fff;
}

.tabs.condition-tabs .ui-state-active a,
.tabs.condition-tabs .ui-state-active a:link,
.tabs.condition-tabs .ui-state-active a:visited {
  color: #202632;
  border-bottom: 2px solid #202632;
}

.tabs.condition-tabs.ui-tabs .ui-tabs-panel {
  border-top: 1px solid #c7c8cb;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 0;
  box-sizing: border-box;
}

/*period-tabs*/
.tabs.ui-tabs .period-tabs .ui-tabs-nav li {
  width: 33% !important;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.tabs.period-tabs .ui-state-default,
.tabs.period-tabs .ui-widget-content .ui-state-default,
.tabs.period-tabs .ui-widget-header .ui-state-default,
.tabs.period-tabs .ui-button,
html .tabs.period-tabs .ui-button.ui-state-disabled:hover,
html .tabs.period-tabs .ui-button.ui-state-disabled:active {
  background: #f5f5f6;
}

.tabs.period-tabs .ui-state-default a,
.tabs.period-tabs .ui-state-default a:link,
.tabs.period-tabs .ui-state-default a:visited,
.tabs.period-tabs a.ui-button,
.tabs.period-tabs a:link.ui-button,
.tabs.period-tabs a:visited.ui-button,
.tabs.period-tabs .ui-button {
  font-size: 12px;
  font-family: 'NotoSansKR-Medium';
  color: #c7c9cc;
}

.tabs.period-tabs.ui-tabs .ui-tabs-panel {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 30px 22px 0 22px;
}

.tabs.period-tabs .ui-state-active a,
.tabs.period-tabs .ui-state-active a:link,
.tabs.period-tabs .ui-state-active a:visited {
  color: #202632;
  border-bottom: 0;
}

.period-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  padding: 12px 0;
}

.period-wrap {
  display: flex;
  justify-content: space-between;
}

.period-box {
  width: calc(50vw - 30px);
  border: 1px solid #707070;
  border-radius: 20px;
  padding: 15px 0 15px 15px;
}

.period-box .bar {
  padding-left: 10px;
  position: relative;
}

.period-box .bar:before {
  content: '';
  display: block;
  width: 4px;
  height: 15px;
  background: #202632;
  position: absolute;
  left: 0;
  top: 5px;
}

.period-box p {
  font-size: 16px;
  font-family: 'NotoSansKR-Medium';
  padding-left: 10px;
}

.chart {
  margin-top: 20px;
}

.chart img {
  width: 100%;
}

.img-area {
  margin: 22px;
}

.img-area img {
  width: 100%;
}

.news-wrap {
  margin: 22px;
}

.btn-area {
  margin: 22px;
}

/*sub-tabs*/
.tabs.ui-tabs.sub-tabs .ui-tabs-nav {
  border-bottom: 1px solid #707070;
}

.tabs.ui-tabs.sub-tabs .ui-tabs-nav li {
  display: inline-block;
  width: auto !important;
  margin: 0;
  padding: 0 0 0 22px;
  border: 0;
  border-radius: 0;
}

.tabs.sub-tabs .ui-state-default,
.tabs.sub-tabs .ui-widget-content .ui-state-default,
.tabs.sub-tabs .ui-widget-header .ui-state-default,
.tabs.sub-tabs .ui-button,
html .tabs.sub-tabs .ui-button.ui-state-disabled:hover,
html .tabs.sub-tabs .ui-button.ui-state-disabled:active {
  background: #fff;
}

.tabs.sub-tabs .ui-state-default a,
.tabs.sub-tabs .ui-state-default a:link,
.tabs.sub-tabs .ui-state-default a:visited,
.tabs.sub-tabs a.ui-button,
.tabs.sub-tabs a:link.ui-button,
.tabs.sub-tabs a:visited.ui-button,
.tabs.sub-tabs .ui-button {
  font-size: 17px;
  font-family: 'NotoSansKR-Medium';
  color: #c7c9cc;
}

.tabs.sub-tabs.ui-tabs .ui-tabs-panel {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 10px 22px 62px 22px;
}

.tabs.sub-tabs .ui-state-active a,
.tabs.sub-tabs .ui-state-active a:link,
.tabs.sub-tabs .ui-state-active a:visited {
  color: #202632;
  border-bottom: 2px solid #eb93a5;
}

.sub-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  padding: 12px 0;
}

.sort-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.sort-select {
  display: flex;
  justify-content: flex-end;
}

.sort-group div {
  display: flex;
}

.sort-group div button {
  padding: 6px 10px;
  border: 1px solid #202632;
  border-radius: 50px;
  font-size: 14px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  margin-right: 5px;
}

.sort-group div button.active {
  border: 1px solid #e76b84;
  background: #e76b84;
  color: #fff;
}

.sort-group div a {
  padding: 6px 10px;
  border: 1px solid #202632;
  border-radius: 50px;
  font-size: 14px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  margin-right: 5px;
}
.sort-group div a.active {
  border: 1px solid #e76b84;
  background: #e76b84;
  color: #fff;
}

.btn-list {
  width: 19px;
  height: 19px;
  background: url('../images/ico_list.png') no-repeat 0 0 / 100% auto;
}

.btn-list span {
  font-size: 0;
}

.btn-block {
  width: 19px;
  height: 19px;
  background: url('../images/ico_block.png') no-repeat 0 0 / 100% auto;
}

.btn-block span {
  font-size: 0;
}

.sort-select select {
  border: 0;
}

/*펀딩리스트*/
.list-group {
  margin-bottom: -20px;
}

/* .list-group > div p {
  margin-bottom: 10px;
  font-size: 12px;
} */

.ico-keep {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 19px;
  height: 19px;
  z-index: 999;
}

.list-group .list-img img {
  width: 100%;
}

.list-group.list-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 18px;
}

.list-group.list-block > div {
  width: calc(50% - 7px);
  margin-bottom: 20px;
}

.list-group.list-block .list-img {
  position: relative;
  margin-bottom: 10px;
}

.list-group.list-line {
  display: block;
  margin-top: 18px;
}

.list-group.list-line.list-padding {
  padding: 10px 22px 0px 22px;
}

.list-group.list-line > div {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.list-group.list-line .list-img {
  position: relative;
  margin-bottom: 0;
  width: 22%;
}

.list-group.list-line > div > div:nth-child(2) {
  width: calc(78% - 10px);
}

.list-group.list-line .list-title {
  position: relative;
  display: flex;
}

.list-group.list-line .ico-keep {
  width: 19px;
  height: 19px;
}

.list-group.list-line .ico-keep img {
  width: 100%;
}

.list-group.list-line .funding-info {
  display: flex;
  justify-content: space-between;
}

.list-group.list-line > div p {
  margin-bottom: 5px;
}

.list-group.list-block .list-title .ico-keep {
  display: none;
}

.list-group.list-block .list-img .ico-keep {
  display: block;
}

.list-group.list-line .list-title .ico-keep {
  display: block;
}

.list-group.list-line .list-img .ico-keep {
  display: none;
}

/*농장탭*/
.farm-box {
  border: 1px solid #202632;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.farm-box:last-child {
  margin-bottom: 0;
}

.farm-box .funding-tag {
  background: #eb93a5;
  position: absolute;
  left: 15px;
  top: 15px;
  font-family: 'NotoSansKR-Medium';
  font-size: 12px;
  padding: 3px 12px;
}

.farm-box .farm-img {
  width: 100%;
}

.farm-box .farm-img img {
  width: 100%;
}

.farm-box-wrap {
  padding: 15px;
}

.farm-box .title {
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
  font-size: 15px;
  margin-bottom: 10px;
}

.farm-box .farm-info {
  font-size: 12px;
  margin-bottom: 15px;
}

.farm-box .keyword {
  display: flex;
  flex-wrap: wrap;
}

.farm-box .keyword p {
  background: #fdf4f6;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  font-size: 12px;
}

/*farm detail*/
.farm-detail {
  padding: 22px 22px;
  border-bottom: 7px solid #e8e9ea;
}

.farm-detail > div {
  margin-bottom: 10px;
}

.farm-detail h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.farm-detail .grow {
  display: flex;
  align-items: center;
}

.farm-detail .grow p {
  color: #585c65;
  font-size: 15px;
  margin-right: 10px;
}

.farm-detail dl {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.farm-detail .ico {
  width: 19px;
  display: inline-block;
}

.farm-detail .ico img {
  width: 100%;
}

.farm-detail dl dt {
  color: #585c65;
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
  font-size: 15px;
  margin-right: 10px;
}

.farm-detail .quality {
  display: flex;
}

.farm-detail .quality dl {
  width: 50%;
}

.farm-detail .price dl {
  margin-bottom: 10px;
}

.farm-detail .cost-detail {
  background: #e8e9ea;
  padding: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
}

.farm-detail .btn-up {
  display: inline-block;
  position: absolute;
  right: 5px;
}

.farm-detail .cost-detail dl {
  justify-content: space-between;
  margin-bottom: 10px;
}

.farm-detail .cost-detail dl:nth-child(3) {
  border-top: 1px solid #b6b8bc;
  padding-top: 10px;
}

.farm-detail .cost-detail dd {
  font-size: 15px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

.farm-detail .unit-num {
  padding: 27px 22px;
  background: #e8e9ea;
}

.farm-detail .unit-num dl {
  justify-content: space-between;
  font-size: 15px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

/*toggle*/
.toggle {
  --width: 40px;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);

  display: inline-block;
  cursor: pointer;
}

.toggle__input {
  display: none;
}

.toggle__fill {
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background: #dddddd;
  transition: background 0.2s;
}

.toggle__fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: var(--height);
  width: var(--height);
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  transition: transform 0.2s;
}

.toggle__input:checked ~ .toggle__fill {
  background: #d7d7d7;
}

.toggle__input:checked ~ .toggle__fill::after {
  transform: translateX(var(--height));
  background: #202632;
}

.percent {
  margin-bottom: 20px;
}

.percent .bar-bg {
  width: 100%;
  height: 6px;
  background: #202632;
  margin-top: 10px;
}

.percent .bar {
  height: 6px;
  background: #eb93a5;
}

.farm-detail .keyword {
  display: flex;
  flex-wrap: wrap;
}

.farm-detail .keyword p {
  background: #fdf4f6;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  font-size: 12px;
}

.farm-detail .intro {
  padding: 30px 20px;
  background: #fdf4f6;
}

.farm-detail .intro p {
  margin-bottom: 15px;
}

.farm-detail .checkup-box {
  background: #f2f2f3;
  border-radius: 0 25px 0 0;
  padding: 25px 13px;
}

.farm-detail .checkup-box dl {
  display: flex;
  margin-top: 25px;
}

.farm-detail .checkup-box dt,
.farm-detail .checkup-box dd {
  width: calc(50% - 5px);
  margin: 0;
  border-top: 1px solid #707070;
  padding: 10px 0;
}

.farm-detail .checkup-box > dl dt:first-child {
  border: 0;
}

.farm-detail .checkup-box > dl dd:nth-child(2) {
  border: 0;
}

.farm-detail .checkup-box dt {
  padding-left: 5px;
}

.farm-detail .checkup-box dd {
  text-align: right;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  color: #e76b84;
  padding-right: 5px;
}

.farm-detail .checkup-box .ultrasound dl {
  background: #fff;
  border: 1px solid #707070;
}

.farm-detail .checkup-box .ultrasound dt,
.farm-detail .checkup-box .ultrasound dd {
  width: calc(50% - 30px);
  border: 0;
  padding: 15px;
}

/*펀딩*/
.circle-progress {
  width: 120px;
  height: 120px;
  background: #585c65;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

.circle-progress > div {
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
}

.circle-progress > div img {
  width: 78px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -39px;
  margin-top: -21px;
}

.balance {
  display: flex;
  justify-content: space-between;
  background: #e8e9ea;
  margin: 20px 0 15px;
  padding: 15px;
}

.floating {
  width: calc(100% - 44px);
  position: fixed;
  padding: 22px;
  bottom: 0;
  background: #fff;
  z-index: 9999;
}

/*keypad*/
.keypad {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0;
}

.keypad > div {
  width: 33%;
  font-size: 22px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
}

.balance-alert {
  width: calc(100% - 84px);
  padding: 14px 20px;
  position: fixed;
  left: 22px;
  bottom: 100px;
  background: rgba(32, 38, 50, 0.75);
  border-radius: 10px;
  color: #fff;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(32, 38, 50, 0.5);
}

.agreement {
  width: calc(100% - 44px);
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 40px 22px;
}

.agreement > h1 {
  margin-bottom: 30px;
}

.agreement > p {
  margin-bottom: 10px;
}

.agreement > .btn {
  margin-top: 30px;
}

.finalCheck {
  width: 70%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px 22px;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.finalCheck .btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.finalCheck .btn-container span {
  margin: 0 10px;
  margin-top: 1em;
  width: 7.5em;
}

/*shop*/
.product-header {
  padding: 22px;
}

.farm-detail.deliver {
  border-top: 1px solid #e8e9ea;
}

.ico-put {
  background: #575b64;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 19px;
  height: 19px;
  padding: 5px;
  border-radius: 20px;
}

.star-point {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url('../images/star_point.png') no-repeat 0 0 / 100% auto;
  vertical-align: middle;
  margin-top: -3px;
}

.star-point-half {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url('../images/star_point_half.png') no-repeat 0 0 / 100% auto;
  vertical-align: middle;
  margin-top: -3px;
}

.arrival {
  background: #e8e9ea;
  padding: 10px 15px;
  margin-top: 5px;
}

/*fixed-menu*/
.fixed-menu.ontop {
  position: fixed;
  top: 100px;
  width: 100%;
  background: #fff;
  z-index: 100;
}

.fixed-menu {
  border-bottom: 1px solid #e8e9ea;
}

.fixed-menu ul {
  display: flex;
  padding: 0 22px;
}

.fixed-menu li {
  width: 25%;
  text-align: center;
}

.fixed-menu li a {
  display: block;
  padding: 14px 0;
  margin-bottom: -1px;
  font-size: 15px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  color: #c7c9cc;
}

.fixed-menu li a.active {
  border-bottom: 2px solid #202632;
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
  color: #202632;
}

/*상품정보*/
.product {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.product.open {
  height: auto;
}

.product .product-more {
  position: absolute;
  bottom: 0;
  width: calc(100% - 44px);
}

.scroll-tab .farm-detail > div {
  margin-bottom: 0;
}

.farm-detail .info {
  margin-top: 22px;
}

.farm-detail .info dl {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e9ea;
  padding: 10px 0;
}

.farm-detail .info dl:last-child {
  border-bottom: 0;
}

.farm-detail .info dt {
  width: 30%;
  font-size: 14px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  color: #202632;
  word-break: keep-all;
}

.farm-detail .info dd {
  width: 60%;
  text-align: left;
  font-size: 14px;
  color: #8f9298;
}

/*후기*/
.point-wrap {
  padding: 0 22px 22px;
}

.point-wrap > div {
  display: flex;
  justify-content: center;
  background: #e8e9ea;
  padding: 10px 0;
}

.point-wrap > div img {
  width: 19px;
  vertical-align: middle;
  margin-top: -5px;
}

.point-wrap > div p {
  display: inline-block;
}

.review-img {
  padding-bottom: 22px;
}

.review-wrap {
  border-top: 1px solid #e8e9ea;
}

.review-wrap > div {
  padding: 10px 22px;
}

.review-wrap.review {
  border-top: 0;
}

.review-wrap.review > div {
  padding: 0;
  margin-bottom: 20px;
}

.review-wrap .star-wrap {
  margin-right: 10px;
  margin-top: -2px;
  letter-spacing: -0.25em;
}

.fixed-menu.filter-menu li a {
  padding: 10px 0;
  font-size: 14px;
}

.fixed-menu.filter-menu li a.active {
  border-bottom: 0;
}

/*상품문의*/
.about-query p {
  position: relative;
  padding-left: 10px;
  word-break: keep-all;
}

.about-query p:before {
  display: block;
  content: '-';
  position: absolute;
  left: 0;
}

.input-query select {
  width: 100%;
  border: 1px solid #c7c8cb;
  padding: 0 15px;
  height: 37px;
  border-radius: 0;
  font-family: 'NotoSansKR-Regular', 'Roboto-Regular';
}

.input-query textarea {
  width: 100%;
  border: 1px solid #c7c8cb;
  padding: 15px;
  text-indent: 0;
  height: 300px;
  border-radius: 0;
  margin-top: 15px;
  font-family: 'NotoSansKR-Regular', 'Roboto-Regular';
}

.blank-txt {
  padding: 100px 0;
}

/*장바구니*/
.order-list-title {
  padding: 15px 22px;
  margin-bottom: 0 !important;
  position: relative;
}

.order-list-title h2 {
  margin-bottom: 0;
}

.order-list {
  padding: 15px 22px;
  border-top: 1px solid #e8e9ea;
  display: flex;
  position: relative;
}

.order-list .form-checkbox {
  margin-right: 10px;
}

.product-data {
  width: 100%;
}

.cart-qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.cart-qty input {
  height: 31px;
  border: 0;
  width: 93px;
}

.cart-qty .price {
  font-weight: bold;
  font-size: 15px;
}

.cart-qty .price span {
  font-size: 14px;
  font-weight: 400;
  color: #8e9197;
  text-decoration: line-through;
}

.order-list .btn-delete {
  position: absolute;
  right: 22px;
  top: 15px;
}

.cart-qty .spiner-wrap {
  height: 31px;
  border: 1px solid #c7c8cb;
  width: 93px;
  display: flex;
  text-align: center;
  align-items: center;
}

.cart-qty .spiner-wrap a {
  width: 30px;
  margin-top: -2px;
}

.cart-qty .spiner-wrap .minus.disabled {
  opacity: 0.25;
}

.cart-qty .spiner-wrap img {
  width: 10px;
}

.cart-qty .spiner-wrap .number {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 30px;
  text-align: center;
  width: 50px;
  font-weight: bold;
}

.price-list dl {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 15px;
}

.total-price {
  font-weight: bold;
  padding: 15px 0;
}

.total-price dd {
  font-size: 20px;
}

/*주문/결제*/
.shop-detail {
  border-bottom: 7px solid #e8e9ea;
}

.shop-detail .inner {
  padding: 0 22px 20px;
  word-break: keep-all;
}

.shop-detail .inner p {
  font-size: 15px;
  margin-bottom: 10px;
}

.shop-detail .inner select {
  width: 100%;
  height: 38px;
  padding-left: 15px;
  border: 1px solid #202632;
  border-radius: 0;
}

.shop-detail .btn-arrow-down,
.btn-arrow-down {
  width: 14px;
  height: 14px;
  background: url('../images/arrow_down.png') no-repeat 0 0 / 100% auto;
  margin-left: 5px;
}

.shop-detail .btn-arrow-up,
.btn-arrow-up {
  width: 14px;
  height: 14px;
  background: url('../images/arrow_up.png') no-repeat 0 0 / 100% auto;
  margin-left: 5px;
}

.shop-detail .btn-arrow-down span,
.shop-detail .btn-arrow-up span,
.btn-arrow-up span,
.btn-arrow-down span {
  font-size: 0;
  height: 0;
}

.address-title {
  padding: 10px 22px;
}

.btn-address {
  display: inline-block;
  width: auto;
  background: #e8e9ea;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 500;
}

.shop-detail .inner dl {
  display: flex;
}

.shop-detail .inner dl:last-child {
  margin-bottom: 0;
}

.shop-detail .inner dt {
  width: 22%;
  padding: 8px 0;
  color: #8f9298;
}

.shop-detail .inner dd {
  width: 100%;
  padding: 8px 0;
}

.shop-detail .inner input,
.shop-detail .inner select {
  border: 1px solid #202632;
  box-sizing: border-box;
  height: 38px;
}

.email-wrap {
  display: flex;
  margin-bottom: 5px;
}

.input-email {
  width: 90% !important;
}

.email-at {
  width: 10%;
  text-align: center;
  margin-bottom: 0 !important;
  padding: 10px 0;
}

.product-list {
  border: 1px solid #202632;
}

.product-list:nth-child(n + 2) {
  margin-top: 10px;
}

.product-list p {
  margin-bottom: 0 !important;
}

.product-list .product-title {
  display: flex;
  justify-content: space-between;
  background: #e8e9ea;
  padding: 10px 15px;
}

.product-list .product-box {
  display: flex;
  padding: 15px;
}

.product-list .product-box .product-img img {
  width: 60px;
}

.product-img {
  margin-right: 10px;
}

.product-list .product-box .product-name {
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  font-size: 15px;
  height: 38px;
}

.product-list .product-box .order-price {
  font-weight: bold;
}

.product-list .product-box .order-price span {
  font-size: 12px;
  font-weight: normal;
  color: #8f9298;
  margin-left: 5px;
}

.payment {
  padding-bottom: 20px;
}

.payment ul {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #eaebee;
  border-bottom: 1px solid #eaebee;
}

.payment li {
  width: 25%;
  text-align: center;
  border-right: 1px solid #eaebee;
  border-bottom: 1px solid #eaebee;
  box-sizing: border-box;
}

.payment li span {
  display: block;
  font-size: 12px;
  color: #09addb;
  font-weight: bold;
}

.payment li a {
  height: calc(125px - 20px);
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
}

.payment li a.active {
  height: 100%;
  background: #effaff;
  border: 2px solid #09addb;
  box-sizing: border-box;
}

.payment li .payment-ico {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.payment li .payment-ico .ico-card {
  width: 35px;
  height: 25px;
}

.payment li .payment-ico .ico-cash {
  width: 38px;
  height: 30px;
}

.payment li .payment-ico .ico-kakao {
  width: 50px;
  height: 20px;
}

.payment li .payment-ico .ico-chai {
  width: 32px;
  height: 32px;
}

.payment li .payment-ico .ico-payco {
  width: 53px;
  height: 12px;
}

.payment li .payment-ico .ico-naver {
  width: 33px;
  height: 33px;
}

.payment li .payment-ico .ico-toss {
  width: 35px;
  height: 30px;
}

.payment li .payment-ico .ico-mobile {
  width: 23px;
  height: 37px;
}

.payment-agree .label {
  width: auto;
  display: inline-block;
  margin-left: 8px;
}

.agree-inner {
  background: #f3f4f4;
  padding: 15px 22px;
}

.agree-inner .form-checkbox {
  padding: 10px 0;
}

.agree-inner .form-checkbox a {
  display: inline-block;
  font-weight: bold;
  margin-left: 15px;
}

.agree-check {
  background: #f3f4f4;
  border-top: 1px solid #dddfe0;
  padding: 15px 22px;
}

.agree-check p {
  color: #898d93;
}

.shop-detail .price-list {
  padding: 0 22px 20px;
}

.shop-detail .price-list dl {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 15px;
}

.shop-detail .price-list dd {
  font-weight: bold;
  color: #585c65;
}

.shop-detail .price-list dl:last-child {
  margin-bottom: 0;
}

.shop-detail .price-list dl.total-price {
  font-weight: bold;
  padding: 15px 0;
  font-size: 20px;
}

.shop-detail .price-list dl.total-price dd {
  color: #202632;
}

.address-top {
  border-top: 15px solid #e8e9ea;
}

.address-box {
  margin: 0 15px 15px;
  background: #fff;
}

.address-box .btn-area {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.address-box .btn,
.btn-find {
  width: auto;
  display: inline-block;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  padding: 7px 12px;
  border: 1px solid #202632;
  border-radius: 0;
}

.address-box .btn + .btn {
  margin-left: 5px;
}

.address-box .btn.btn-black {
  background: #202632;
  color: #fff;
}

.shop-detail .disabled {
  background: #f1f1f2;
  border: 1px solid #c6c7ca !important;
}

.search-address {
  position: relative;
}

.search-address input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #202632;
}

.search-address .ico-search {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 19px;
}

.search-address .ico-search img {
  width: 100%;
}

/*후기*/
.review-div {
  position: fixed;
  width: 100%;
  height: 100%;
}

.review-page {
  text-align: center;
  align-items: center;
  justify-content: center !important;
  margin-top: -78px;
}

.review-page img {
  width: 30px;
}

/*마이뱅크*/
.bg-my,
header.bg-my {
  background: #f0f1f3;
}

.my-menu {
  background: #dbdddf;
}

.my-menu ul {
  display: flex;
  padding: 10px 0;
}

.my-menu li {
  width: 50%;
  text-align: center;
  padding: 5px 0;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

.my-menu li:first-child {
  border-right: 1px solid white;
}

.my-box {
  background: #dbdddf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-top: 20px;
}

.my-box > div {
  display: flex;
}

.btn-edit {
  width: 14px;
  height: 14px;
  background: url('../images/ico_edit.png') no-repeat 0 center / 100% auto;
  margin-left: 5px;
}

.btn-dot {
  width: 14px;
  height: 14px;
  background: url('../images/ico_dot.png') no-repeat 0 center / 100% auto;
  margin-left: 5px;
}

.btn-edit span,
.btn-dot span {
  font-size: 0;
  height: 0;
}

.account-box {
  background: #fff;
  padding: 15px;
  margin-top: 20px;
}

.account-img {
  width: 37px;
  height: 37px;
  background: #8f9298;
  border-radius: 100%;
}

.btn-arrow-right {
  width: 14px;
  height: 14px;
  background: url('../images/arrow_right_bk.png') no-repeat 0 0 / 100% auto;
  margin-left: 5px;
}

.btn-arrow-right span {
  font-size: 0;
  height: 0;
}

.my-board {
  margin-top: 20px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.my-board > div {
  flex-grow: 1;
  background: #fff;
  height: 90px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.my-board > div > p:first-child {
  font-size: 15px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

.my-board > div > p:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
}

.history-list {
  background: #fff;
  margin-top: 20px;
}

.history-list p {
  border-bottom: 1px solid #e8e9ea;
  text-align: center;
}

.history-list p:last-child {
  border-bottom: 0;
}

.history-list p a {
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  padding: 13px 0;
}

input.text {
  height: 37px;
}

.input-file {
  position: relative;
}

.input-file a {
  position: absolute;
  right: 15px;
  top: 25px;
}

.input-file a img {
  width: 19px;
}

.q-list {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #202632;
  border-top: 1px solid #202632;
}

.q-list li {
  width: 25%;
  text-align: center;
  border-right: 1px solid #202632;
  border-bottom: 1px solid #202632;
  box-sizing: border-box;
}

.q-list li a {
  padding: 7px 0;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

.q-list li a.active {
  background: #202632;
  color: #fff;
}

.form-group label {
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
}

.form-group p {
}

.form-group input {
  margin-top: 5px;
}

.my-account {
  border-bottom: 7px solid #e8e9ea;
}

.my-account dl {
  display: flex;
  justify-content: space-between;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  padding: 15px 0;
}

.my-account dt {
  font-size: 15px;
}

.my-account dd {
  font-size: 20px;
}

.btn-order {
  width: 19px;
  height: 19px;
  background: url('../images/ico_order.png') no-repeat 0 0 / 100% auto;
}

.btn-order span {
  font-size: 0;
}

.search-history {
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
}

.search-history > div {
  padding: 40px 22px;
}

.search-history ul {
  display: flex;
  border-left: 1px solid #202632;
  border-top: 1px solid #202632;
  box-sizing: border-box;
}

.search-history li {
  border-right: 1px solid #202632;
  border-bottom: 1px solid #202632;
  box-sizing: border-box;
  flex: 2;
  text-align: center;
}

.search-history li a {
  padding: 12px 0;
}

.search-history li a.active {
  background: #202632;
  color: #fff;
}

.search-tit {
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  margin-bottom: 15px;
}

.search-period {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid #C7C8CB; */
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 30px;
}

.search-period p {
  margin: 0px 5px;
}

.search-period input {
  background: white !important;

  height: 40px;
  border: 0;
  text-align: center;
  text-indent: 0;

  font-size: 14px !important;
}

.bank-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 22px;
  margin-top: 20px;
}

.bank-list li {
  width: 32%;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  text-align: center;
  padding: 14px 0;
  background: #fafafb;
  border-radius: 20px;
  margin-bottom: 7px;
}

.account-img {
  width: 37px;
  height: 37px;
  border-radius: 100%;
  background: #8f9298;
}

.form-passowrd {
  display: flex;
  justify-content: center;
  margin-top: 75px;
}

.form-passowrd input {
  width: 19px;
  height: 19px;
  background: #c6c7ca;
  border: 0;
  border-radius: 19px;
  margin-right: 9px;
}

.form-passowrd input:last-child {
  margin-right: 0;
}

.form-passowrd input.enter {
  background: #202632;
}

/*setting*/

/*toggle btn*/
.toggleSwitch {
  width: 49px;
  height: 28px;
  display: block;
  position: relative;
  border-radius: 30px;
  background-color: #8f9298;
  cursor: pointer;
}

.toggleSwitch .toggleButton {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
}

.toggleInput:checked ~ .toggleSwitch {
  background: #eb93a5;
}

.toggleInput:checked ~ .toggleSwitch .toggleButton {
  left: calc(100% - 22px);
  background: #fff;
}

.toggleSwitch,
.toggleButton {
  transition: all 0.2s ease-in;
}

.gray-box {
  background: #e8e9ea;
}

/*popup*/
.popup {
  width: calc(90% - 40px);
  height: 110px;
  background: #fff;
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -45%;
  margin-top: -75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pop-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -35px;
  margin-left: -35px;
}

.pop-loading span {
  width: 70px !important;
  height: 70px !important;
}

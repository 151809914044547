.farm_box {
  border: 1px solid #202632;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.farm_box:last-child {
  margin-bottom: 0;
}

.farm_box .funding_tag {
  background: #eb93a5;
  position: absolute;
  left: 15px;
  top: 15px;
  font-family: 'NotoSansKR-Medium';
  font-size: 12px;
  padding: 3px 12px;
}

.farm_box .farm_img {
  width: 100%;
}

.farm_box .farm_img img {
  width: 100%;
  aspect-ratio: 2 / 1;
}

.farm_box_wrap {
  padding: 15px;
}

.farm_box .title {
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
  font-size: 15px;
  margin-bottom: 10px;
}

.farm_box .farm_info {
  font-size: 12px;
  margin-bottom: 15px;
}

.farm_box .keyword {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.farm_box .keyword p {
  background: #fdf4f6;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-family: 'NotoSansKR-Medium', 'Roboto-Medium';
  font-size: 12px;
}

.container {
  position: relative;
  /* The container determains the size. */
  width: 100%;
  height: 100vh;
}

.container > .loading-overlay {
  /* We'll render the overlay on top of the Unity Application. */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* We'll set the following Flex properties in order to center the text. */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cornflowerblue;
}

.container > .unity {
  /* The Unity Application matches it size to the container. */
  width: 100%;
  height: 100vh;
}

.unity-back {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 2.5rem;
  height: 2.5rem;
}

.unity-back:hover {
  cursor: pointer;
  opacity: 0.8;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login,
.logout {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 14px;

  background-color: white;
}

.login:hover,
.logout:hover {
  color: white;
  background-color: black;
  cursor: pointer;
}

.notice {
  position: relative;
  width: 20px;
  height: 20px;
}

.notice > p {
  display: block;
  background-color: red;
  width: 5px;
  height: 5px;

  position: absolute;
  right: 0px;
}

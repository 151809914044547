button {
  background: none;
}

.tooltip_btn > img {
  width: 19px;
}

.arrow_btn {
  background: none;
}

.arrow_btn > img {
  width: 18px;
}

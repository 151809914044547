.history_list {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.history_list > button {
  background-color: white;
  padding: 0.75rem;
  border: 1px solid #e8e9ea;
}

.birth {
  color: #8f9298;
  font-size: 13px;
}

.user_info {
  display: flex;
  align-items: center;
}

.aacount_num {
  color: #8f9298;

  display: flex;
  align-items: center;
}

.clipboard_img {
  width: 14px;
  height: 14px;

  margin-left: 10px;
}

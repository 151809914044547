.goback {
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  top: 30vh;
}
.speakerClass {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 50px;
  top: 72.5vh;
  background-color: rgba(49, 49, 49, 0.772);
}
.speakerText {
  display: flex;
  color: aliceblue;
  font-size: large;
  padding-top: 11px;
  margin: 0 auto;
}
.speakerIcon {
  padding-right: 10px;
  padding-top: 1px;
}
.versionClass {
  position: fixed;
  display: flex;
  align-items: center;
  width: 70vw;
  height: 40px;
  top: 67vh;
  left: 5vw;
  background-color: rgba(255, 255, 255, 0.623);
  border-radius: 5px;
  padding: 0 10px;
}
.versionText {
  width: 100%;

  display: flex;
  color: rgb(22, 22, 22);
  font-weight: bold;
  font-size: 18px;
}

.versionClass2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 45vw;
  height: 40px;
  top: 67vh;
  left: 50vw;
  background-color: rgba(255, 255, 255, 0.623);
  border-radius: 5px;
}
.versionText2 {
  color: rgb(22, 22, 22);
  font-weight: bold;
  font-size: 18px;
}

.speakerDivider {
  height: 25px;
  width: 1px;
  background-color: #ffffff;
  margin: 12px 0;
}

@charset "utf-8";
@import url('font.css');

/* Reset */
* {
    outline: 0 !important;
}

html, body, h1, h2, h3, h4, h5, h6, div, p, blockquote, pre, code, address, ul, ol, li, menu, nav, section, article, aside, dl, dt, dd, table, thead, tbody, tfoot, label, caption, th, td, form, fieldset, legend, hr, input, button, textarea, object, figure, figcaption {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

html {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    width: 100%;
    background: #fff;
    min-width: 320px;
    -webkit-text-size-adjust: none;
    word-wrap: break-word;
    word-break: break-all;
    letter-spacing: -0.05em;
    font-size: 14px;
    font-family: 'NotoSansKR-Regular', sans-serif;
    color: #202632;
    position: relative;
}

body,
input,
select,
textarea,
button {
    border: none;
}

ul,
ol,
li {
    list-style: none;
}

table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

img,
fieldset {
    border: 0;
}

address,
cite,
code,
em {
    font-style: normal;
    font-weight: normal;
}

label,
img,
input,
select,
textarea,
button {
    vertical-align: middle;
}

.hide,
caption,
legend {
    line-height: 0;
    font-size: 1px;
    overflow: hidden;
}

hr {
    display: none;
}

main,
header,
section,
nav,
footer,
aside,
article,
figure {
    display: block;
}

a {
    display: block;
    color: #000;
    text-decoration: none;
}

/* Form */
textarea {
    border: 1px solid #dbdbdb;
}

select {
    height: 32px;
    font-size: 14px;
    color: #373737;
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 5px;
}

input[type=tel],
input[type=time],
input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=file],
input[type=url],
input[type=number],
input[type=date],
textarea {
    width: 100%;
    height: 48px;
    font-size: 14px;
    color: #373737;
    border: 1px solid #c1c2c6;
    background: #fff;
    text-indent: 20px;
    transition: all 0.5s;
    vertical-align: middle;
    box-sizing: border-box;
}

input::-webkit-input-placeholder {
    color: #b5b5b5;
    font-size: 14px;
    line-height: 100%;
}

textarea {
    padding: 5px 0;
}

select:focus,
textarea:focus,
input:focus {
    border: 1px solid #727272;
}

input[type=tel][readonly],
input[type=text][readonly],
input[type=password][readonly],
input[type=email][readonly],
input[type=search][readonly],
input[type=tel][disabled],
input[type=text][disabled],
input[type=password][disabled],
input[type=search][disabled],
input[type=email][disabled] {
    background: #eaeaea;
    border-color: #c0c0c0;
    color: #666;
    -webkit-appearance: none;
    font-size: 12px;
}

textarea[readonly],
textarea[disabled] {
    padding: 11px;
    font-size: 14px;
    color: #666;
    font-weight: normal;
    line-height: 140%;
    height: 78px;
    background: #eaeaea;
    border: 1px solid #c0c0c0;
}

.clear {
    clear: both;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}
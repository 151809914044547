.address {
  display: flex;
  align-items: center;
  padding: 5px 22px;
}

.address > span {
  margin-right: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}

.orderer {
  display: flex;
  gap: 10px;
}

.arrow_img {
  width: 16px;
  height: 16px;
}

.inner {
  padding: 0 22px 20px;
  word-break: keep-all;
}

.inner > dl {
  display: flex;
  margin-bottom: 15px;
}

.inner dt {
  width: 75px;

  display: flex;
  align-items: center;
}

.inner dl:last-child {
  display: flex;
  align-items: flex-start;
}

.inner dd {
  width: 100%;
}

.inner input {
  width: 100%;
  height: 35px;
}

.select_email {
  width: 100%;
  height: 35px;
  padding: 0 16px;
}

.container {
  display: flex;
  flex-direction: column;
}

.swiper_img {
  width: 100%;
}

.swiper_img > img {
  width: 100%;
  aspect-ratio: 1 / 0.8;
}

.tooltip_img {
  margin-left: 10px;
}

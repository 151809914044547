.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  height: 90%;
}

.login > h1 {
  text-align: center;
}

.login > img {
  width: 80%;
  border-radius: 2.5rem;
}

.kakao_btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.privacy_btn {
  /* border: 1px solid gray;
  border-radius: 0.5rem; */
  background-color: white;
  /* padding: 0.5rem 2rem; */
  margin-bottom: 1rem;
}

.privacy_btn:hover {
  border-bottom: 1px solid black;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px;
}

.container > h1 {
  font-size: 18px;
  font-family: 'NotoSansKR-Bold', 'Roboto-Bold';
}

.img_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 18px;
  height: 18px;
  z-index: 999;

  color: white;
  background: none;
}

.funding_info {
  display: flex;
  flex-direction: column;
}

.funding_info > p {
  font-size: 12px;
}

.funding_info > p span:nth-child(2) {
  font-weight: 550;
}

.progress {
  background: #202632;
  width: 100%;
  height: 2px;
  margin-top: 5px;
}

.progress_bar {
  background: #eb93a5;
  height: 2px;
}

.thumbnail {
  aspect-ratio: 1 / 1;
  border-radius: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header > div {
  width: 100%;
  text-align: end;
}

.dibs > img {
  width: 20px;
  height: 20px;
}

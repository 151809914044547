.prev_btn {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 9999;
}

.prev_btn > img {
  width: 20px;
  height: 20px;
}

.header {
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 1rem 1rem;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top > h1 {
  font-size: 1rem;
}

.prev_btn > img {
  width: 18px;
  height: 18px;
}

.cart_btn > img {
  width: 18px;
  height: 18px;
}

.rate {
  margin-left: 10px;
}

.discount_rate {
  color: #e76b84;
  margin-right: 10px;
}

.product_info_btn,
.buy_btn {
  background: #e76b84;
  color: #fff;
  margin-top: 10px;
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.product_info_btn > img {
  margin-left: 10px;
  width: 13px;
}

.star_wrap {
  margin-right: 10px;
}

.cart_buy_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

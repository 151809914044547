@charset 'utf-8';

/*notosans*/
@font-face {
	font-family: 'NotoSansKR-Light';
	src: url('./fonts/NotoSansKR/NotoSansKR-Light.woff') format('woff'),
		url('./fonts/NotoSansKR/NotoSansKR-Light.ttf')  format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: 'NotoSansKR-Thin';
	src:url('./fonts/NotoSansKR/NotoSansKR-Thin.woff') format('woff'),
		url('./fonts/NotoSansKR/NotoSansKR-Thin.ttf')  format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: 'NotoSansKR-Regular';
	src: url('./fonts/NotoSansKR/NotoSansKR-Regular.woff') format('woff'),
		url('./fonts/NotoSansKR/NotoSansKR-Regular.ttf')  format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: 'NotoSansKR-Medium';
	src: url('./fonts/NotoSansKR/NotoSansKR-Medium.woff') format('woff'),
		url('./fonts/NotoSansKR/NotoSansKR-Medium.ttf')  format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: 'NotoSansKR-Bold';
	src: url('./fonts/NotoSansKR/NotoSansKR-Bold.woff') format('woff'),
		url('./fonts/NotoSansKR/NotoSansKR-Bold.ttf')  format('truetype');
    unicode-range: U+AC00-D7A3;
}

@font-face {
	font-family: 'NotoSansKR-Black';
	src: url('./fonts/NotoSansKR/NotoSansKR-Black.woff') format('woff'),
		url('./fonts/NotoSansKR/NotoSansKR-Black.ttf')  format('truetype');
    unicode-range: U+AC00-D7A3;
}



/*roboto*/
@font-face {
    font-family: 'Roboto-Regular';
    src: url(./fonts/Roboto/Roboto-Regular.woff) format('woff'),
         url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
         unicode-range: U+0030-0039, U+0041-005A, U+0061-007A,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url(./fonts/Roboto/Roboto-Medium.woff) format('woff'),
         url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
         unicode-range: U+0030-0039, U+0041-005A, U+0061-007A,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url(./fonts/Roboto/Roboto-Bold.woff) format('woff'),
         url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
         unicode-range: U+0030-0039, U+0041-005A, U+0061-007A,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}
@font-face {
    font-family: 'Roboto-Black';
    src: url(./fonts/Roboto/Roboto-Black.woff) format('woff'),
         url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
         unicode-range: U+0030-0039, U+0041-005A, U+0061-007A,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img_btn > img {
  height: 1.75rem;
}

.fund_btn {
  background: #e76b84;
  color: #fff;
  text-align: center;
  width: 80%;
  height: 2.5rem;
  border-radius: 0.5rem;
}
